import React from "react"
import PhotoGallery from "../../components/photogallery/PhotoGallery"
import Layout from "../../components/layout/Layout"
import { getImageNodes } from "../../lib"
import { graphql } from "gatsby"
import { StoryHeader } from "../../components/storyheader/StoryHeader"

export default function Birlanagar({ data }) {
  const IMAGES = getImageNodes(data)
  const [COVER_IMAGE] = IMAGES
  return (
    <Layout title="Birlanagar" heading="Birlanagar">
      <StoryHeader storyImage={COVER_IMAGE}>
        <>
        <p>
          A suburb of the Indian city Gwalior, Birlanagar came into existence in
          the post independence industrial development that took place in the
          country. The township became home to one of the leading industrial
          groups-The Birla group’s textile business. The mills at their peak
          employed over 12000 people who inhabited the residential quarters
          surrounding the compound.
        </p>
        <p>
          After a glorious run lasting half a century, various reasons and
          discords drove the business house away leaving behind empty mill
          compounds with families bereft of any livelihood.
        </p>
        <p>
          To this day, the inhabitants continue to live in the relinquished
          township, their past glory dilapidated with the fallen structures, and
          their next generation, born of ruin, attempts to build a future under
          the shadow of a town abandoned.
        </p>
        <p>
          I often crossed Birlanagar on my visits to Gwalior, my birth place,
          and it had always piqued my interest. This work is an outcome of my
          longing to understand and explore this city. It is difficult to bring
          a conclusion to this work and I consider it as an exploration of the
          place and its people emerging from the legacy of despair but looking
          unto the future with new hope.
        </p>
        </>
      </StoryHeader>

      <PhotoGallery photos={IMAGES} />
    </Layout>
  )
}

export const query = graphql`
  query Birlanagar {
    allFile(filter: { relativeDirectory: { eq: "birlanagar" } }) {
      edges {
        node {
          name
          base
          publicURL
        }
      }
    }
  }
`
