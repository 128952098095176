import React from "react"
import "./StoryHeader.css"

export const StoryHeader = ({storyImage, children}) => {
  return (
    <div className="story-header-container">
      <article className="story-header-content">
        {children}
      </article>
      <img
        src={storyImage.src}
        className="story-header-image"
        alt={storyImage.name}
      />
    </div>
  )
}
